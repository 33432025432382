<template>
  <div class="policy">
    <TopTabbar title="隐私政策" text="返回"/>
    <div class="center" v-html="content">
    </div>
  </div>
</template>

<script setup>
  import { reqPager } from '../../../API/user'
  import { onMounted,ref } from 'vue'

  const content = ref('')
  onMounted(async () => {
    const result = await reqPager({title:'隐私政策'})
    if(result.code == 1) content.value = result.data[0].content
  })
</script>

<style lang="scss" scoped>
  .policy{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f8f8f8;
    .center{
      width: 100%;
      height: calc(100% - 46px);
      overflow: auto;
      padding: 15px;
    }
  }
</style>